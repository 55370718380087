.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 250px;
  background-color: black;

  .containerFooter {
    width: 1270px;
    max-width: 100vw;
    height: 100px;
    border-top: solid 1px #212121;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    .logoKbi {
      margin-top: 10px;
      font-size: 30px;
      color: white;
      width: 100px;
    }

    .LinkFooter:hover {
      opacity: 0.7;
    }

    .littleTitleGrey {
      font-size: 15px;
      color: #737373;
      margin-bottom: 20px;
    }

    .LinkFooter {
      color: #d2d2d2;
      cursor: pointer;
      transition: opacity ease-in-out 0.2s;
      font-size: 15px;
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .containerLogoSocial {
      margin-top: 30px;
      display: flex;
      gap: 10px;

      img {
        height: 18px;
        cursor: pointer;
      }

      img:hover {
        opacity: 0.7;
      }

    }
  }
}
