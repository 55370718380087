
$font-1: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

$color-1: #4867EC;
$color-2: #61dafb;
$color-2: #FFFFFF;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$color-5: #000000;

$dark-blue: #0f1721;
$light-dark-blue: #1f2c3d;

.color1 {
  color: $color-1;
}

.backgroundColor1 {
  color: $color-1;
}

input {
  font-family: $font-1 ;
}

body {
  margin: 0rem;
  font-family: $font-1;
  color: $color-3;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;

}

html {
  scroll-behavior: smooth;

}

.enableSelection {
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}

.ombre {
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  transition: all 0.3s;
}

.transitionWidthMarginLeft {
  transition: all 0.3s;
}

.transitionOpacity {
  transition: opacity 0.3s ease-in-out;

}

.transitionMenuProjet{
  transition: all 0.3s ease-in-out;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

a {
  text-decoration: none;
  color: $color-3;
  cursor: pointer;
  font-family: $font-1;
}

a:visited {
  color: $color-3;
}

.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

.hover {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.transitionScale:hover {
  transform: scale(1.03);
}

.transitionScale {
  transition: transform ease-in-out 0.2s ;
  cursor: pointer;
}

.degrade {
  background: linear-gradient(45deg, #3D88E4, #60D1BF);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700;
}
