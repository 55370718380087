.infoBanner {
  width: 100vw;
  height: 100px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    color: #FFFFFF;
    max-width: 1000px;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
  }
}

.landingHomePage {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .colorGradient {
    display: flex;
    position: absolute;
    width: 100vw;
  }

  .containerLandingHomePage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logoKbi {
      margin-top: 80px;
      width: 160px;
    }

    .kaizenBudoEurope {
      font-weight: 700;
      font-size: 22px;
      color: #FFFFFF;
      margin-bottom: 0px;
    }

    .international {
      font-weight: 700;
      font-size: 18.5px;
      margin-top: 5px;
      margin-bottom: 0px;
      background: linear-gradient(45deg, #3D88E4, #60D1BF);
      -webkit-background-clip: text;
      color: transparent;
    }

    .mainTitle {
      margin-top: 80px;
      font-size: 55px;
      letter-spacing: -1px;
      width: 780px;
      text-align: center;
      color: #FFFFFF;
      line-height: 60px;
      font-weight: 600;
      word-spacing: 4px;
      max-width: 90vw;
    }

    @media (max-width: 768px) {
      .mainTitle {
        font-size: 40px;
        line-height: 45px;
      }
    }

    .subtitle {
      width: 800px;
      text-align: center;
      color: #7D88A4;
      line-height: 22px;
      max-width: 90vw;
    }

    .buttonMore:hover {
      transform: scale(1.02);
    }

    .buttonMore {
      width: 500px;
      background: linear-gradient(to right, #2D69F5, #3C88D5, #64D9B8);
      height: 60px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 17px;
      color: white;
      margin-top: 50px;
      transition: transform ease-in-out 0.2s;
      cursor: pointer;
      max-width: 90vw;
    }

    .joinClients {
      display: flex;
      align-items: center;
      margin-top: 18px;

      .people {
        height: 30px;
        object-fit: contain;
      }

      .joinPro {
        margin-left: 20px;
        color: #9AA1BB;
        font-size: 16px;
      }
    }

    @media (max-width: 768px) {
      .joinClients {
        flex-direction: column-reverse;
      }
    }

    .infosPart {
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      width: 100%;
      margin-bottom: 150px;
    }

    .logosContainer {
      display: flex;
      animation: scrollLeft 100s linear infinite;
    }

    @keyframes scrollLeft {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-50%);
      }
    }

    .infosPart {
      margin-top: 140px;
      display: flex;

      .logo {
        height: 100px;
        width: 200px;
        object-fit: contain;
        margin-right: 100px;
      }
    }

    @media (max-width: 768px) {
      .infosPart {
        margin-top: 70px;
        margin-bottom: 70px;

        .logo {
          height: 100px;
          width: 100px;
          margin-right: 50px;
        }
      }
    }
  }
}

.secretSuccess {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 1200px;

  .colorGradientLeft {
    display: flex;
    position: absolute;
    left: 0;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  .colorGradientRight {
    display: flex;
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    opacity: 0.5;
  }

  .subtitle {
    margin-top: 120px;
    background: linear-gradient(45deg, #3D88E4, #60D1BF);
    font-weight: 700;
    -webkit-background-clip: text;
    color: transparent;
  }

  .title {
    margin-top: 0;
    font-size: 48px;
    font-weight: 600;
  }

  .threeSteps {
    display: flex;
    margin-top: 60px;

    .textComponent {

      .titleTextComponent {
        font-size: 28px;
        font-weight: 700;
        width: 300px;
        margin-bottom: 0px;
      }

      .titleEnd {
        background: linear-gradient(45deg, #3D88E4, #60D1BF);
        -webkit-background-clip: text;
        color: transparent;
      }

      .subtitleTextComponent {
        color: #7D88A4;
        width: 350px;
        font-size: 14px;
      }
    }

    .secret1 {
      margin-top: -50px;
      width: 300px;
      margin-bottom: 70px;
    }

    .secret2 {
      margin-top: 70px;
      width: 300px;
      margin-bottom: 70px;
    }

    .secret3 {
      margin-top: 120px;
      width: 300px;
      margin-bottom: 25px;
    }

    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 50px;
      margin-right: 50px;

      .square {
        width: 60px;
        height: 60px;
        background: linear-gradient(to bottom right, #2D69F5, #3C88D5, #64D9B8);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 20px;
      }

      .blackBar {
        width: 3px;
        height: 250px;
        background-color: #2D69F5;

      }

    }
  }

}

.secretSuccessMobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;

  .colorGradientLeft {
    display: flex;
    position: absolute;
    left: 0;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  .colorGradientRight {
    display: flex;
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    opacity: 0.5;
  }

  .subtitle {
    margin-top: 120px;
    background: linear-gradient(45deg, #3D88E4, #60D1BF);
    font-weight: 700;
    -webkit-background-clip: text;
    color: transparent;
    max-width: 90vw;
  }

  .title {
    margin-top: 0;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 25px;
    max-width: 90vw;
    text-align: center;
  }

  .threeSteps {
    display: flex;
    margin-top: 60px;

    .textComponent {

      .titleTextComponent {
        font-size: 28px;
        font-weight: 700;
        width: 300px;
        margin-bottom: 0px;
      }

      .titleEnd {
        background: linear-gradient(45deg, #3D88E4, #60D1BF);
        -webkit-background-clip: text;
        color: transparent;
      }

      .subtitleTextComponent {
        color: #7D88A4;
        width: 350px;
        font-size: 14px;
      }
    }

    @media (max-width: 768px) {
      .textComponent {

        .subtitleTextComponent {
          font-size: 17px;
        }
      }

      .secret1 {
        width: 200px;
        margin-bottom: 30px;
      }
    }

    .square {
      width: 50px;
      height: 50px;
      background: linear-gradient(to bottom right, #2D69F5, #3C88D5, #64D9B8);
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 18px;
      margin-right: 30px;
    }

    .secret1 {
      width: 250px;
      margin-bottom: 30px;
    }

    .secret2 {
      margin-top: 70px;
      width: 250px;
      margin-bottom: 10px;
    }

    .secret3 {
      margin-top: 70px;
      width: 250px;
      margin-bottom: 10px;
    }
  }
}


.part1Formation {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/conference.png'); /* Remplacez 'chemin_vers_votre_image.jpg' par le chemin de votre image */
  background-size: cover; /* Pour couvrir tout l'espace disponible */
  background-repeat: no-repeat; /* Pour éviter que l'image ne se répète */
  background-position: center; /* Pour centrer l'image dans le conteneur */

  .intervenantPart {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 600px;

    .intervenant {
      border-radius: 10px;
      width: 100%;
    }
  }

  .textPart {
    margin-left: 70px;
    width: 45%;
  }

  .widgetFormation {
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 250px;
    padding-bottom: 15px;

    .logo512 {
      width: 45px;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 15px;
    }

    .name {
      font-weight: 700;
    }

    .cible, .duree, .where {
      margin-left: 10px;
      border-radius: 20px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      padding: 3px 10px;
    }

    .cible {
      background-color: #6997fd;
    }

    .duree {
      background-color: #67c96e;
    }

    .where {
      margin-top: 10px;
      background-color: #ffbf6c;
    }
  }
}

.part2Elearning {
  overflow: hidden;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/elearning.png'); /* Remplacez 'chemin_vers_votre_image.jpg' par le chemin de votre image */
  background-size: cover; /* Pour couvrir tout l'espace disponible */
  background-repeat: no-repeat; /* Pour éviter que l'image ne se répète */
  background-position: center; /* Pour centrer l'image dans le conteneur */

  .intervenantPart {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .intervenant {
      border-radius: 10px;
      width: 100%;
    }
  }

  .textPart {
    margin-right: 30px;
    width: 45%;
  }
}

.part1Formation, .part2Elearning {
  max-width: 1450px;
  margin-top: 30px;
  border-radius: 20px;
  width: calc(100vw - 60px);
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 60px;

  .containerPart {
    display: flex;
    align-items: center;
    justify-content: center;

    .textPart {
      .step {
        margin-top: 0px;
        background: linear-gradient(45deg, #3D88E4, #63D7BB);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 700;
      }

      .titleStep {
        margin-top: 0px;
        font-weight: 700;
        color: #FFFFFF;
        font-size: 40px;
        margin-bottom: 0px;
      }

      .corpsStep {
        color: #d7dff3;
        font-size: 17px;
        margin-right: 30px;
        line-height: 25px;
      }
    }
    .button:hover {
      transform: scale(1.02);
    }

    .button {
      width: 300px;
      background: linear-gradient(to right, #2D69F5, #3C88D5, #64D9B8);
      height: 50px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 17px;
      color: white;
      margin-top: 30px;
      transition: transform ease-in-out 0.2s;
      cursor: pointer;
      margin-bottom: -25px;
    }
  }

  .backgroundImage {
    width: calc(100vw - 60px);
    border-radius: 20px;
    opacity: 0.3;
    object-fit: cover;
    display: flex;
  }
}

@media (max-width: 946px) {
  .part2Elearning {
    padding-top: 50px;
    display: flex;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)), url('../../assets/elearning.png'); /* Remplacez 'chemin_vers_votre_image.jpg' par le chemin de votre image */

    .containerPart {
      flex-direction: column-reverse;
      justify-content: center;

      .textPart {
        margin-top: 20px;
        width: 100%;
        margin-right: 0px;

        .step {
          margin-top: -20px;
        }

        .titleStep {
          color: black;
          font-size: 30px;
        }

        .corpsStep {
          text-align: justify;
          color: #1f2c3d;
        }
      }

      .button {
        width: 100%;
      }
    }

    .intervenantPart {
      width: 100%;

      .intervenant {
        border-radius: 10px;
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }

  .part1Formation {
    border-radius: 0px;
    padding-top: 0px;
    display: flex;
    flex-direction: column-reverse;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)), url('../../assets/conference.png'); /* Remplacez 'chemin_vers_votre_image.jpg' par le chemin de votre image */

    .containerPart {
      justify-content: center;
      flex-direction: column;

      .textPart {
        margin-top: 20px;
        margin-left: 0px;
        width: calc(100vw - 60px);

        .step {
          margin-top: 20px;
        }

        .titleStep {
          color: black;
          font-size: 30px;
        }

        .corpsStep {
          text-align: justify;
          color: #1f2c3d;
        }
      }

      .button {
        width: 100%;
      }

      .intervenantPart {
        width: 100%;

        .intervenant {
          border-radius: 10px;
          margin-bottom: 30px;
          width: 100%;
        }
      }
    }

    .widgetFormation {
      background-color: #FFFFFF;
      border-radius: 10px;
      width: calc((100vw - 90px)/2);
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

      .logo512 {
        margin-right: 10px;

      }

      .cible, .duree, .where {
        font-size: 12px;
      }
    }

  }
}

.valeursFondatrices {
  display: flex;
  flex-direction: column;
  align-items: center;

  .firstTitle {
    margin-top: 150px;
    background: linear-gradient(45deg, #3D88E4, #60D1BF);
    font-weight: 700;
    -webkit-background-clip: text;
    color: transparent;
  }

  .mainTitle {
    margin-top: 0;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 100px;
    text-align: center;
  }

  .valeur {
    margin-bottom: 60px;
    width: 1200px;
    max-width: calc(100vw - 150px);
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;

    .textPart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 60px;
      padding-right: 60px;

      .title {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 0px;
      }

      .degrade {
        background: linear-gradient(45deg, #3D88E4, #60D1BF);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 700;
      }

      .corps {
        line-height: 27px;
        font-size: 17px;
        color: #606060;

        strong {
          color: #000000;
        }
      }

    }

    .picturePart {
      display: flex;
      align-items: center;
      img {
        width: 380px;
        border-radius: 10px;
        margin-right: -50px;
      }
    }
  }
}

.valeursFondatricesMobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .firstTitle {
    margin-top: 70px;
    background: linear-gradient(45deg, #3D88E4, #60D1BF);
    font-weight: 700;
    -webkit-background-clip: text;
    color: transparent;
  }

  .mainTitle {
    margin-top: 0;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 100px;
    text-align: center;
    max-width: 80vw;
  }

  .containerValeurs {
    display: flex;
    width: 100vw;
    gap: 15px;
    overflow: scroll;

    .comble {
      width: 40px;
      height: 40px;
      color: #FFFFFF;
    }

    .valeur {
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(83, 228, 61)), url('../../assets/conference.png'); /* Remplacez 'chemin_vers_votre_image.jpg' par le chemin de votre image */
      background-size: cover; /* Pour couvrir tout l'espace disponible */
      background-repeat: no-repeat; /* Pour éviter que l'image ne se répète */
      background-position: center; /* Pour centrer l'image dans le conteneur */
      margin-bottom: 60px;
      border-radius: 20px;
      display: flex;
      padding-top: 15px;
      padding-bottom: 15px;
      height: 280px;

      .textPart {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 20px;
        padding-right: 20px;
        color: #FFFFFF;

        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 0px;
          width: 200px;
          text-align: center;
        }

        .degrade {
          background: linear-gradient(45deg, #ffffff, #ffffff);
          -webkit-background-clip: text;
          color: transparent;
          font-weight: 700;
        }

        .corps {
          line-height: 27px;
          font-size: 17px;
          color: #606060;

          strong {
            color: #000000;
          }
        }
      }

      .picturePart {
        display: flex;
        align-items: center;
        img {
          width: 380px;
          border-radius: 10px;
          margin-right: -50px;
        }
      }
    }
  }
}

.testimonies {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;

  .title {
    margin-top: 60px;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 70px;
    text-align: center;
  }

  .youtube {

  }

  @media (max-width: 768px) {
    .youtube {
      max-width: 90vw;
      height: calc(90vw * 0.56);

    }
  }


}

.cta {
  padding-top: 150px;
  display: flex;
  background-color: black;
  justify-content: center;
  padding-bottom: 150px;

  .widgetCta {
    width: 630px;
    max-width: calc(100vw - 40px);
    border: solid 3px #4CA4D0;
    border-radius: 15px;
    background-color: #03151C;
    box-shadow: 0px 0px 20px 0px rgba(76, 164, 208, 0.57);
    display: flex;
    flex-direction: column;
    align-items: center;

    .startNow {
      margin-top: 50px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0px;
      text-align: center;
      margin-left: 20px;
      margin-right: 20Px;
    }

    .subtitle {
      color: #d5d5d5;
      text-align: center;
      margin-left: 20px;
      margin-right: 20Px;
    }

    .title {
      margin-top: 35px;
      font-size: 45px;
      font-weight: 600;
      word-spacing: 4px;
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 35px;
    }

    .buttonCta:hover {
      transform: scale(1.02);
    }

    .buttonCta {
      width: 450px;
      max-width: calc(100vw - 80px);
      margin-bottom: 20px;
      background: linear-gradient(to right, #2D69F5, #3C88D5, #64D9B8);
      height: 75px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 17px;
      color: white;
      margin-top: 30px;
      margin-bottom: 40px;
      transition: transform ease-in-out 0.2s;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      flex-direction: column;
    }

    .mail {
      text-decoration: underline;
    }

  }
}

@media (max-width: 768px) {
  .cta {
    padding-top: 50px;
    padding-bottom: 50px;

    .widgetCta {
      .title {
        font-size: 35px;
        margin-bottom: 20px;
      }
    }


  }
}

.frequentlyAskedQuestions {
  padding-top: 0px;
  display: flex;
  background-color: black;
  align-items: center;
  padding-bottom: 150px;
  flex-direction: column;

  .colorGradient {
    display: flex;
    position: absolute;
    transform: rotate(180deg);
    margin-top: -410px;
  }

  .title {
    font-size: 45px;
    color: #FFFFFF;
    margin-bottom: 0px;
  }

  .subtitle {
    color: #c5c5c5;
    margin-bottom: 80px;
    width: 600px;
    text-align: center;
    line-height: 25px;
  }

  .question:hover {
    transform: scale(1.02);
  }

  .question {
    width: 700px;
    background-color: #FFFFFF;
    border-radius: 20px;
    height: 90px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    justify-content: space-between;
    cursor: pointer;
    transition: transform ease-in-out 0.2s;

    .questionTitle {
      font-weight: 600;
      font-size: 18px;
    }

    .plus {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 40px;
      font-weight: 200;
      margin-right: 30px;
    }
  }
}
